/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "../Basemap.js";
import r from "../config.js";
import t from "../core/Collection.js";
import n from "../core/Logger.js";
import { destroyMaybe as a, mappedFind as i } from "../core/maybe.js";
import { Url as l, makeAbsolute as s, normalize as u } from "../core/urlUtils.js";
import { ensureType as c } from "../core/accessorSupport/ensureType.js";
import { effectEquals as o } from "../layers/effects/utils.js";
import { esriBasemapDefinitions as f } from "./basemapDefinitions.js";
const p = () => n.getLogger("esri.support.basemapUtils");
function y() {
  return {};
}
function m(e) {
  for (const r in e) {
    const t = e[r];
    a(t), delete e[r];
  }
}
function b(t, n) {
  let a;
  if ("string" == typeof t) {
    const i = (t in f),
      l = !i && t.includes("/");
    if (!i && !l) {
      const e = Object.entries(f).filter(([e, t]) => r.apiKey && !t.classic || !r.apiKey && (t.classic || t.is3d)).map(([e]) => `"${e}"`).sort().join(", ");
      return p().warn(`Unable to find basemap definition for: ${t}. Try one of these: ${e}`), null;
    }
    n && (a = n[t]), a || (a = i ? e.fromId(t) : new e({
      style: {
        id: t
      }
    }), n && (n[t] = a));
  } else a = c(e, t);
  return a?.destroyed && (p().warn("The provided basemap is already destroyed", {
    basemap: a
  }), a = null), a;
}
function d(e) {
  return "Web Scene" === e.portalItem?.type || e.referenceLayers.some(e => "scene" === e.type);
}
function L(e) {
  return !!e?.portalItem?.tags?.some(e => "beta" === e.toLowerCase());
}
function S(e, r) {
  return e.basemap?.referenceLayers?.some(e => e.uid === r) || e.basemap?.baseLayers?.some(e => e.uid === r);
}
function v(e, r = null) {
  const t = b(e);
  if (!t) return null;
  const n = t.clone();
  return r && (n.baseLayers = T(n.baseLayers, r.baseLayers), n.referenceLayers = T(n.referenceLayers, r.referenceLayers)), n;
}
let g;
function h(e) {
  if (g) return g(e);
  let r = null;
  const t = M(e),
    n = !t?.baseLayers.length;
  for (const a in f) {
    const e = K(t, $(f[a]), {
      mustMatchReferences: n
    });
    if ("equal" === e) {
      r = a;
      break;
    }
    "base-layers-equal" === e && (r = a);
  }
  return r;
}
function I(e) {
  g = e;
}
function R(e, r) {
  if (e === r) return !0;
  if (null != e?.portalItem?.id && e.portalItem.id === r?.portalItem?.id) return !0;
  return "equal" === K(M(e), M(r), {
    mustMatchReferences: !0
  });
}
function T(e, r) {
  const n = new t();
  return e.forEach(e => {
    const t = r.find(r => "scene" !== r.type && D(A(e), A(r))) || e;
    n.includes(t) ? n.push(e) : n.push(t);
  }), n;
}
function w(e) {
  return !!e?.baseLayers.concat(e.referenceLayers).some(j);
}
function j(e) {
  if (x(e.url)) return !0;
  if ("vector-tile" === e.type) for (const r in e.sourceNameToSource) {
    const t = e.sourceNameToSource[r];
    if (x(t?.sourceUrl)) return !0;
  }
  return !1;
}
function U(e, r) {
  if (null == r || null == e) return {
    spatialReference: null,
    updating: !1
  };
  if ("not-loaded" === r.loadStatus) return r.load(), {
    spatialReference: null,
    updating: !0
  };
  if (r.spatialReference) return {
    spatialReference: r.spatialReference,
    updating: !1
  };
  if (0 === r.baseLayers.length) return {
    spatialReference: null,
    updating: !1
  };
  const t = r.baseLayers.at(0);
  switch (t.loadStatus) {
    case "not-loaded":
      t.load();
    case "loading":
      return {
        spatialReference: null,
        updating: !0
      };
    case "failed":
      return {
        spatialReference: null,
        updating: !1
      };
  }
  const n = (("supportedSpatialReferences" in t ? t.supportedSpatialReferences : null) || ["tileInfo" in t ? t.tileInfo?.spatialReference : t.spatialReference]).filter(Boolean),
    a = e.spatialReference;
  return a ? {
    spatialReference: n.find(e => a.equals(e)) ?? n[0] ?? null,
    updating: !1
  } : {
    spatialReference: n[0],
    updating: !1
  };
}
const q = /^(basemaps|ibasemaps).*-api\.arcgis\.com$/i;
function x(e) {
  if (!e) return !1;
  const r = new l(s(e));
  return !!r.authority && q.test(r.authority);
}
function M(e) {
  return e ? !e.loaded && e.resourceInfo ? $(e.resourceInfo.data) : {
    baseLayers: k(e.baseLayers),
    referenceLayers: k(e.referenceLayers)
  } : null;
}
function k(e) {
  return (t.isCollection(e) ? e.toArray() : e).map(A);
}
function A(e) {
  return {
    type: e.type,
    effect: "effect" in e ? e.effect : void 0,
    url: E("urlTemplate" in e && e.urlTemplate || e.url || "styleUrl" in e && e.styleUrl || ""),
    minScale: "minScale" in e && null != e.minScale ? e.minScale : 0,
    maxScale: "maxScale" in e && null != e.maxScale ? e.maxScale : 0,
    opacity: null != e.opacity ? e.opacity : 1,
    visible: null == e.visible || !!e.visible,
    sublayers: "map-image" !== e.type && "wms" !== e.type || null == e.sublayers ? void 0 : e.sublayers?.map(e => ({
      id: e.id,
      visible: e.visible
    })),
    activeLayerId: "wmts" === e.type ? e.activeLayer?.id : void 0
  };
}
function C(e) {
  return e.isReference || "ArcGISSceneServiceLayer" === e.layerType;
}
function $(e) {
  return e ? {
    baseLayers: G((e.baseMapLayers ?? []).filter(e => !C(e))),
    referenceLayers: G((e.baseMapLayers ?? []).filter(e => C(e)))
  } : null;
}
function G(e) {
  return e.map(e => B(e));
}
function B(e) {
  let r;
  switch (e.layerType) {
    case "VectorTileLayer":
      r = "vector-tile";
      break;
    case "ArcGISTiledMapServiceLayer":
      r = "tile";
      break;
    case "ArcGISSceneServiceLayer":
      r = "scene";
      break;
    default:
      r = "unknown";
  }
  return {
    type: r,
    effect: e.effect,
    url: E(e.templateUrl || e.urlTemplate || e.styleUrl || e.url),
    minScale: e.minScale ?? 0,
    maxScale: e.maxScale ?? 0,
    opacity: e.opacity ?? 1,
    visible: null == e.visibility || !!e.visibility,
    sublayers: void 0,
    activeLayerId: void 0
  };
}
function K(e, r, t) {
  if (null != e != (null != r)) return "not-equal";
  if (!e || !r) return "equal";
  if (!N(e.baseLayers, r.baseLayers)) return "not-equal";
  return N(e.referenceLayers, r.referenceLayers) ? "equal" : t.mustMatchReferences ? "not-equal" : "base-layers-equal";
}
function N(e, r) {
  if (e.length !== r.length) return !1;
  for (let t = 0; t < e.length; t++) if (!D(e[t], r[t])) return !1;
  return !0;
}
function D(e, r) {
  if (e.type !== r.type || e.url !== r.url || e.minScale !== r.minScale || e.maxScale !== r.maxScale || e.visible !== r.visible || e.opacity !== r.opacity) return !1;
  if (!o(e.effect, r.effect)) return !1;
  if (null != e.activeLayerId || null != r.activeLayerId) return e.activeLayerId === r.activeLayerId;
  if (null != e.sublayers || null != r.sublayers) {
    if (null == e.sublayers || null == r.sublayers || e.sublayers.length !== r.sublayers.length) return !1;
    for (let t = 0; t < e.sublayers.length; t++) {
      const n = e.sublayers.at(t),
        a = r.sublayers.at(t);
      if (n?.id !== a?.id || n?.visible !== a?.visible) return !1;
    }
  }
  return !0;
}
function E(e) {
  return e ? u(e).replace(/^\s*https?:/i, "").toLowerCase() : "";
}
function O(e) {
  if (!e) return null;
  const {
    thumbnailUrl: r
  } = e;
  if (r) return r;
  const t = h(e);
  return t ? f[t].thumbnailUrl : i(e.baseLayers, V);
}
function V(e) {
  return "portalItem" in e ? e.portalItem?.thumbnailUrl : void 0;
}
export { v as clonePreservingTiledLayers, R as contentEquals, y as createCache, m as destroyCache, b as ensureType, U as findSpatialReference, O as getBasemapThumbnailUrl, h as getWellKnownBasemapId, w as hasDeveloperBasemapLayer, d as isBasemap3D, L as isBasemapInBeta, S as isBasemapLayer, j as isDeveloperBasemapLayer, I as overrideGetWellKnownBasemapId };